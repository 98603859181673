
/* <<---------------- animacion cursor-----------------> */
.cursorCustom {
  position: absolute;
  height: 112px;
  width: 112px;
  background: rgba(0, 87, 255, 1);
  color: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 9999; /* Valor alto para estar siempre al frente */
}

.cursorCustom::before {
  padding: 31px;
  content: "Ver";
  position: absolute;
  height: 80%;
  width: 80%;
  font-size: 18px;
  color: white;
  left: 10%;
  top: 10%;
  background: rgba(0, 87, 255, 1);
  border-radius: 50%;
  z-index: -1; /* Valor bajo para estar detrás del cursor principal */
}

.cursorCustom.click {
  content: "Ver";
  height: 115px;
  color: white;
  font-size: 18px;
  padding: 45px;
  width: 115px;
  border: 1px solid rgba(0, 87, 255, 1);
  z-index: 9999; /* Valor alto para estar siempre al frente al hacer clic */
}

.cursorCustom.click::before {
  content: "Ver";
  color: white;
  font-size: 18px;
  padding: 35px;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 87, 255, 1);
  z-index: -1; /* Valor bajo para estar detrás del cursor principal */
}

  *{
    cursor: none !important;
  }

  .cursorPointer{
    position: absolute;
    height: 48px;
    cursor: default !important;
    pointer-events: none;
    width: 48px;
    background: rgba(0, 87, 255, 1);
    color: white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    z-index: 9999; /* Valor alto para estar siempre al frente */
    justify-content: center;
  }
  .cursor-arrow-pointer{
    position: absolute;
    right: -5px;
    bottom: -10px;
  }
  
  .cursorPointer::before {
  
    content: "";
    position: absolute;
    height: 80%;
    width: 80%;
    color: white;
    left: 10%;
    top: 10%;
    background: rgba(0, 87, 255, 1);
    border-radius: 50%;
  }
  
  .cursorPointer.click {
    content: "";
    height: 55px;
    color: white;
    width: 55px;
    border: 1px solid rgba(0, 87, 255, 1);
  }
  
  .cursorCustom.click::before {
    content: "";
    color: white;
    padding: 35px;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 87, 255, 1);
  }
  
/* <------------------------DEFAULT-------------> */
  
  .cursorDefoult{
    position: absolute;

    cursor: default !important;
    pointer-events: none;
  
    /* background: rgba(0, 87, 255, 1); */
    color: white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    z-index: 9999; /* Valor alto para estar siempre al frente */
    justify-content: center;
  }
  .cursor-arrow{
    position: absolute;
 top: 0;
  }
  
  .cursorDefoult::before {
  
    content: "";
    position: absolute;
    height: 80%;
    width: 80%;
    color: white;
    left: 10%;
    top: 10%;
    /* background: rgba(0, 87, 255, 1); */
    border-radius: 50%;
  }
  
  .cursorDefoult.click {
    content: "";
    height: 55px;
    color: white;
    width: 55px;
    /* border: 1px solid rgba(0, 87, 255, 1); */
  }
  