.content-block-component-linkld {
    width: 100%;

}

.content-lkd {
    display: flex;
    justify-content: center;
    margin: auto;
    background: rgba(242, 246, 255, 1);
    width: 80%;
    /* height: 224px; */
    padding: 32px 40px 32px 40px;
    border-radius: 16px;

}
.content-lkd:hover {
    box-shadow: rgba(17, 17, 178, 0.421) 0px 7px 29px 0px;

}

.content-block-choose {
    padding-top: 50px;
}

.data-item-choose {
    padding: 10px;
}

.data-details {
    padding: 10px;
}

.description-choose {
    font-weight: normal;
    cursor: pointer;
}

.description-choose:hover {
    font-weight: bold;
}

.data-item-choose.highlighted .description {
    font-weight: bold;
}

.data-item {
    padding: 10px;
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.title-responsive {
    text-align: center;
}

.descriptionTitle {
    margin: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    font-weight: 500;
}

.titleObject {
    font-size: 34px;
    font-weight: 700;
}

.data-item.fade-out {
    opacity: 0;
}

.content-block-teams {
    padding-top: 50px;

}

.container-img-teams {
    margin-top: 50px;
}

.description-teams {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    width: 90%;
    margin: auto;
    color: rgba(124, 126, 134, 1);
}

.lightbox {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
}

@media screen and (max-width:361px) {
    .titleObject {
        font-size: x-large !important;
        line-height: 32px;
    }

    .titleObjectMap {

        font-size: large !important;
    }

}

@media screen and (max-width:980px) {
    .titleObject {
        font-size: xx-large;
    }

    .titleObjectMap {
        line-height: 28px !important;
        font-size: x-large;
    }

    .container-img-teams {
        padding: 25px 10px 50px 10px;
        margin-top: 20px !important;
    }

    .description-teams {
        text-align: start;
        font-size: medium;
        width: 100%;
        line-height: 20px;

    }

    .content-lkd {

        padding: 24px 20px !important;


    }

    .content-block-teams {
        padding-top: 20px;

    }

}

@media screen and (max-width:1236px) {

    .content-lkd {
        height: 100% !important;
        width: 100% !important;
    }

    .titleObject,
    .descriptionTitle {
        text-align: start !important;
    }

    .containerFrmk {
        padding-top: 50px;
        padding-bottom: 50px;
        height: 100% !important;
        width: 100% !important;
        margin-left: 0px !important;
        margin-top: 0px !important;
        margin: auto !important;
        display: flex;
        justify-content: center;

    }

}

.logo-phone-header {
    margin: 7px 9px 8px 8px;

}

.logo-email-header {

    margin-right: 5px;
}

.logo-phone-header {
    width: 13px;
    margin: 4px 8px 5px 2px;
}

@media screen and (max-width:767px) {
    .phone-compont-video {
        margin-bottom: 50px;
        font-size: medium !important;
    }

    .title-responsive {
        text-align: start;
    }

    .title-compont-video {
        font-size: xx-large !important;
    }

    .subtitle-compont-video {
        margin-bottom: 20px !important;
        font-size: medium !important;
        padding-top: 5px !important;
    }

    .email-compont-video {
        margin-bottom: 5px !important;
        font-size: medium !important;
        padding-top: 5px !important;
    }

    .logo-email-header {
        height: 25px;
        margin-right: 5px;
    }

    .logo-phone-header {
        width: 9px;
        margin: 4px 7px 5px 1px;
    }

    .content-vide-description {
        top: auto !important;
        bottom: 0px !important;
        left: auto !important;
        width: 100% !important;
    }

    .content-vide-description-privacy {
        top: 150px !important;
        bottom: revert !important;
        left: 10px !important;
        width: 100% !important;
    }
    .grapImg {
        padding-top: 25% !important;
    }
    .content-block-header-blue-privacity{
        height: 250px !important;
        background-color: rgba(0, 87, 255, 1);
    
    }
}
.content-block-header-blue-privacity{
    height: 400px;
    background-color: rgba(0, 87, 255, 1);

}
@media screen and (max-width:447px) {
    .video-lopp {
        width: auto !important;
        /* max-height: 560px; */
        /* background-repeat: space; */
    }

    .container-map {
        max-height: 250px;
        position: relative;
        width: 100% !important;
        margin-top: 35px;
        margin-left: 0px;
    }

    .google-maps-content {
        width: 98% !important;
        height: 94% !important;
        position: absolute;
        top: 7px !important;
        left: 0px !important;
    }

    .border-map {
        width: 50% !important;
        height: 100% !important;
        background: rgba(0, 87, 255, 1);
        position: absolute;
        left: auto !important;
    }

    .btn-cv {
        padding: 10px 8px !important;
    }

    .img-adj {
        padding-right: 2px !important;
    }

    .container-img-teams {
        padding: 30px 0px;

    }

    .title-compont-video {
        font-size: x-large !important;
        line-height: 24px !important;
    }

    .subtitleFrmk {
        font-size: 13px !important;
        line-height: 24px !important;
    }

    .titleFrmk {
        font-size: 18px !important;
        line-height: 26px !important;
    }

    .ux-img {
        width: 90% !important;
    }

    .listFrmk {
        font-size: 14px !important;
        line-height: 24px !important;
    }

    .li-framework {
        padding: 10px 8px 0px 0px;

    }
}

.li-framework {
    padding: 15px 8px 0px 0px;

}

@media screen and (max-width:900px) {

    .grapImg {
        margin-left: 0px !important;

        margin-top: -30% !important;
    }

    .boxFrmk {
        height: 100% !important;
        width: 100% !important;
        border-left: none !important;
        padding: 20px 5px !important;


    }


}


.component-framwork {
    background-color: rgba(242, 246, 255, 1);
    height: 100%;
    padding-top: 70px;
    padding-bottom: 120px;
}

.containerFrmk {
    height: 555px;
    width: 828px;
    margin-left: 295px;
    margin-top: 50px;
}

.boxFrmk {
    height: 427px;
    width: 659px;
    border-left: 10px solid rgba(0, 87, 255, 1);
    background-color: white;
    padding: 40px;
}

.subtitleFrmk {

    font-size: 18px;
    font-weight: 600;
    line-height: 24px;



}

.titleFrmk {

    font-size: 32px;
    font-weight: 800;
    line-height: 42px;


}

.grapImg {
    margin-left: 795px;
    display: flex;
    justify-content: end;
    margin-top: -230px;
}

.listFrmk {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    list-style: square;

    color: rgba(85, 86, 90, 1);


}

.titleFrmk,
.subtitleFrmk {
    letter-spacing: 0em;
    font-family: Montserrat;
    text-align: left;
    color: rgba(0, 87, 255, 1);
    text-transform: uppercase;
}

li::marker {
    color: rgba(0, 87, 255, 1);
}

.labelForms {
    color: rgba(0, 87, 255, 1);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
}

textarea {
    border: aliceblue;
    width: 100%;
    resize: none !important;
    height: 162px;
    padding: 20px;
}

.form-control-error {
    border: rgb(225, 71, 71) !important;
    border-radius: 0px !important;
    height: 48px;
}

.span-error {
    color: #EF4444;
    text-transform: none;
    margin: 4px 5px 5px 5px;
    font-size: 13px;
}

.form-control-input-forms {

    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    appearance: none;
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}

.form-control,
select {
    border: aliceblue !important;
    border-radius: 0px !important;
    height: 48px;
}

input::placeholder,
.form-select,
textarea::placeholder {
    color: rgba(206, 208, 211, 1) !important;

}

.is-required:after {
    content: '*';
    margin-left: 3px;
    color: rgba(0, 87, 255, 1);
    font-weight: bold;
}

/* ---navbar -----> */

.component-video-header {
    position: relative;
    overflow: hidden;
}

.video-lopp {
    width: 100%;
}

.video-max-width {
    display: contents;
}

.title-compont-video {
    color: white;
    font-size: 40px;
    font-weight: 700;
}

.email-compont-video,
.phone-compont-video {
    padding-top: 5px;
    color: white;
    font-size: 23px;
    font-weight: 500;
    display: flex;
    justify-content: left;
}

.email-compont-video {
    margin-bottom: 4px;
}

.subtitle-compont-video {
    color: white;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 40px;
    max-width: 100%;
    overflow: visible;
    text-overflow: clip;
    white-space: break-spaces;
}

.content-vide-description {
    position: absolute;
    top: 45%;
    left: 35px;
    width: 50%;
}

.content-vide-description-privacy {
    position: absolute;
    top: 25%;
    left: 35px;
    width: 50%;
}

.video-max-width {
    display: block;
}

.video-min-width {
    display: none;
}

.bg-body-white,
.navbar,
.navbar-expand-lg {
    padding: 30px 10px 0px 10px !important;
}

@media screen and(max-width:992px) {

    .navbar {
        padding: 40px 20px !important;
    }
}

@media screen and(max-width:392px) {
    .navbar {
        padding: 5px 5px !important;
    }
}

@media screen and (max-width:767px) {

    .content-component-forms {

        padding-bottom: 40px !important;
    }

    .containerFrmk {
        padding-top: 20px;
        padding-bottom: 15px;


    }

    .video-max-width {
        display: none !important;
    }

    .video-min-width {
        display: block !important;
        width: 100% !important;
    }

    .component-framwork {

        padding-top: 20px;
        padding-bottom: 20px !important;
        margin-top: 20px;
    }

    .containerFrmk {
        margin-bottom: 0px !important;
    }

    .card-client-list {

        padding: 0px !important;

    }
}

.card-client-list {
    border: none !important;
    box-shadow: none;
    padding: 10px;

}

@keyframes tracking-out-contract-bck-top {
    0% {
        transform: translateZ(0) translateY(0);
        opacity: 1;
    }

    60% {
        opacity: 1;
    }

    100% {
        letter-spacing: -0.5em;
        transform: translateZ(-500px) translateY(-300px);
        opacity: 0;
    }
}

@keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.5em;
        transform: translateZ(-800px);
        filter: blur(12px);
        opacity: 0;
    }

    100% {
        transform: translateZ(0);
        filter: blur(0);
        opacity: 1;
    }
}

.text-load {
    color: white;
    animation: tracking-in-expand 0.5s ease-in-out forwards;
    animation-delay: 1.1s;
    text-align: center;
    font-weight: 700;
    display: flex;
    margin: auto;
    justify-content: center;
    margin: auto;
    margin-top: 25%;
}



.text-load.animation-out {
    color: #EF4444 !important;
}

.content-load {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.content-blue1 {
    position: absolute;
    z-index: 999;
    width: 100%;
    aspect-ratio: 1/1;
    background-color: #56AFFF;
    animation-name: animation-box-1;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-play-state: running;
    animation-duration: 5s;
    animation-delay: 0s;
}

.content-blue2 {
    position: absolute;
    z-index: 1000;
    width: 100%;
    aspect-ratio: 1/1;
    background-color: #2F8CFF;
    animation-name: animation-box-2;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-play-state: running;
    animation-duration: 5s;
    animation-delay: 0s;
}

.content-blue3 {

    position: absolute;
    justify-content: center;
    margin: auto;
    z-index: 1001;
    width: 100%;
    aspect-ratio: 1/1;
    background-color: #0057FF;
    animation-name: animation-box-3;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-play-state: running;
    animation-duration: 5s;
    animation-delay: 0s;
}


@keyframes animation-box-1 {
    0% {
        transform: translateY(100%);
    }

    20% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(0);
    }


    100% {
        transform: translateY(-100%);
    }
}



@keyframes animation-box-2 {
    0% {
        transform: translateY(100%);
    }

    30% {
        transform: translateY(0);
    }

    65% {
        transform: translateY(0);
    }


    100% {
        transform: translateY(-100%);
    }
}

@keyframes animation-box-3 {
    0% {
        transform: translateY(100%);
    }

    40% {
        transform: translateY(0);
    }

    70% {
        transform: translateY(0);
    }

    90% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(-100%);
    }
}


@media (max-width: 340px) {
    .modal-content {
        width: 280px !important;
        margin-top: 50% !important;
        margin-left: 12px;
        margin-right: 15px;

    }
}

@media (max-width: 768px) {
    .btn-modal-send {
        height: 38px !important;
        width: 112px !important;
        font-size: 14px !important;
        padding: 8px 12px 10px 12px !important;
    }

    .modal-content {
        margin: auto !important;
        width: 280px !important;
        margin-top: 50% !important;


    }

    .subtitle-modal-send-forms {
        font-size: 14px !important;

    }

    .title-modal-send-forms {
        font-size: 16px !important;

    }

    .content-blue3 {
        animation-name: animation-box-3-responsive;
    }

    .content-blue2 {
        animation-name: animation-box-2-responsive;
    }

    .content-blue1 {
        animation-name: animation-box-1-responsive;
    }

    .text-load {
        margin: 50% 5% 0% 5%;
        font-size: large;
    }

    .content-load {
        /* Adjust properties for responsive layout */
        height: 100vmax;
        /* You might want to experiment with this value */
    }

    .content-modal-send {
        padding: 5px 0px 10px 0px !important;
    }
}

@keyframes animation-box-1-responsive {
    0% {
        transform: translateY(200%);
        height: 100vh;
    }

    20% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(0);
    }

    65% {
        height: 100vh;}
    80%{   transform: translateY(-100%);}

    100% {
        transform: translateY(-100%);
    }
}

@keyframes animation-box-2-responsive {
    0% {
        transform: translateY(200%);
        height: 100vh;
    }

    30% {
        transform: translateY(0);
    }

    60% {
        transform: translateY(0);
    }

    70% {
        transform: translateY(-100%);

    }

    100% {
        transform: translateY(-100%);
    }
}

@keyframes animation-box-3-responsive {
    0% {
        transform: translateY(200%);
        height: 100vh;
    }

    40% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(0);
    }

    60% {
        transform: translateY(-100%);
        height: 100vh;
    }

    100% {
        transform: translateY(-100%);
    }
}

.input-error {
    border: 1px solid #EF4444;
}

input[type="file"] {
    width: 50% !important;
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 8px;
    font-size: 17px;
    color: #b8b8b8;
}

.button-wrap {
    position: relative;
}

.contenedor-pagina-load {

    position: relative;
    width: 100%;
    height: 100vh;
}

.caja-transition {
    height: 250px;
    background-color: #56AFFF;
    width: 100%;
    position: absolute;
    bottom: 0;
    animation: moveUp 2s linear forwards;

}

@keyframes moveUp {
    0% {
        bottom: -100px;
        /* Posición inicial, oculto abajo */
    }

    100% {
        bottom: 0;
        /* Posición final, en el borde superior */
    }
}

/* <---------------MODAL------------> */
.modal-backdrop.show.fade {
    background-color: rgba(8, 29, 68, 0.92) !important;
}

.modal-content {
    border-radius: 0px !important;
    width: 400px;
    margin-top: 40%;

}

.content-modal-send {
    padding: 20px;
}

.btn-modal-send {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 224px;
    height: 48px;
    padding: 10px 16px 10px 16px;
    border-radius: 4px;
    background-color: rgba(47, 140, 255, 1);
    color: white;
    border: none;

}


.title-modal-send-forms {

    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: center;
    color: rgba(29, 29, 29, 1)
}

.subtitle-modal-send-forms {
    display: flex;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: center;
    color: rgba(85, 86, 90, 1);

}

.email-send-img {
    display: flex;
    justify-content: center;
    margin: auto;
    padding-bottom: 15px;
}

.modal-body-email-send {

    padding: 0px 40px 10px 40px !important;
}