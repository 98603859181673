.content-page-not-found {
    background: linear-gradient(302.55deg, #85D2FF 16.3%, #0C61FF 87.86%);
    height: 100vh;
}
/* .block-content-header-error-404{
   position: relative; 
} */
.component-circle {
    width: 100%;
    height: 100vh;
}
.content-information-404{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 100vh;
   
}
.content-col-information-404-rigth{
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../assets//notFound/circulo404.svg')
}
.image-aislamiento{
    position: absolute;
    right: 0;
    z-index: 9999;
}
.image-aislamiento-min{
   
    width: 100%;
   
}
.content-button-information_404{
    margin-top: 20px;
}
.content-text-information_404{
    margin-top: 20px;
    max-width: 450px;
}
.content-col-information-404{
    width: 100%;
   
}
.title-information_404{

font-family: Montserrat;
font-size: 24px;
font-weight: 700;
line-height: 32px;
letter-spacing: -0.02em;
text-align: center;
color: white;

}
.description-information_404{
 color: white;
 font-family: Montserrat;
 font-size: 18px;
 font-weight: 500;
 line-height: 28px;
 letter-spacing: 0em;
 text-align: center;
  
}
.content-information-404-min{
    display: none;
}
@media screen and (max-width:1200px) {
    .content-col-information-404-rigth{
        display: none;
    } 
    .content-information-404-min{
        display: block !important;
    }
    .image-aislamiento-min{
        width: 100%;
    margin-bottom: 20px;
    }
 
}
@media screen and (max-width:700px) {

    .content-number-404{
        width: 100%;
    }
    .title-information_404{

        font-size: 18px !important;
        }
        .description-information_404{
        font-size: 14px !important;
          
        }
}
@media screen and (max-height:740px) {
    .content-information-404-min{
        display: none !important;
    }
 
}