.image-containerSrv {
  position: relative;
  overflow: hidden;
  cursor: pointer;


}

.imageSrv {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 5s;
}
.content-card-services{
  
  margin-bottom: 20px;
}
@media screen and (max-width:400px) {
  .content-card-services{
  margin-bottom: 15px;
}
}

.image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 85, 255, 0);
  display: block;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 35px;
  transform: translateY(65%);
  transition: transform 0.6s;
  transition: background-color 1s ease, transform 1s ease;
  
}

.image-containerSrv:hover .image-overlay {
  transform: translateY(0);
  background-color: rgb(0, 85, 255);


}

.display-parraf {
  display: none;
}

 .image-containerSrv:hover .imageSrv:hover {
  opacity: 5;

  transition: transform 5s;
 }

.image-containerSrv:hover .display-parraf {
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  color: white;

}

.title-services {
  font-size: 26px;
  font-weight: 700;
  color: white;
}

.content-tecnologies {
  padding-top: 70px;
  padding-bottom: 50px;
}

@media screen and(max-width:700px) {

  .image-containerSrv:hover .display-parraf {
  
    font-size:medium !important;
   
  }
 .image-overlay {
  padding: 5px !important;
 }
}
 
.carousel-frmk {
  display: flex;
  overflow: hidden;
  width: 100%;

}

.image-container-frmk {
  display: flex;
  animation: moveLogos linear 20s infinite;}

.logo-frmk {
  width: 100%; /* Ajusta el tamaño según tus necesidades */
  height: 45px;
  margin: 0 20px; /* Ajusta el margen entre logotipos */
}

@keyframes moveLogos {
  0% {
    transform: translateX(calc(-170px * var(--logo-count)));

  }
  100% {
    transform: translateX(-100%);
  }
}

@media screen and (max-width:800px) {


  .component-layout-services{
margin-top: 0px !important;
}
}

.content-tecnologies-min{
  display: block;
}

.component-layout-services{
margin-top: 30px;
}