.image-carousel {
    width: 500px; 
    overflow: hidden;
  }
  
  .image-set {
    display: flex;
    transition: transform 1s;
  }
  
  .image-set.first-set {
    transform: translateX(0);
  }
  
  .image-set:not(.first-set) {
    transform: translateX(-100%);
  }
  