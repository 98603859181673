@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

html,
body {
  /* min-height: 100%; */
  margin: 0;
}

.navigation-left {
  position: absolute;
  left: 0;
  top: 210px;
  width: 45px;
}
.policity-link {
  border-bottom: 1px solid rgb(47, 140, 255);
}
.policity-link:hover {
  border-bottom: 2px solid rgb(47, 140, 255);
}
.navigation-rigth {
  position: absolute;
  right: 0;
  top: 210px;
  width: 45px;
}
.arrow-next-resposive,
.arrow-prev-resposive {
  width: 40px;
}
.carousel-control-prev-icon:after {
  /* background-color: #0057FF !important; */
  content: "" !important;
  background-image: url("./assets//img/components/arrowLeftWeb.svg") !important;
}

.carousel-control-prev-icon {
  /* background-color: #0057FF !important; */
  background-image: url("./assets//img/components/arrowLeftWeb.svg") !important;
}

.noneDecoder:hover,
.lkd-text:hover {
  color: white;
}

.content-forms-clients,
.content-forms-postulant {
  padding-top: 40px;
}

.content-input-forms {
  padding-top: 20px;
}

.card-title-client {
  font-weight: 700;
  font-size: 24px;
}
.card-title-privacy {
  font-weight: 700;
  font-size: 18px;
}
.card-description-client,
.card-description-privacy {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}
.card-description-privacy {
  color: rgba(29, 29, 29, 1);
}
.content-description-forms {
  color: rgba(0, 87, 255, 1);
}

.content-description-forms {
  font-size: 18px;
  font-weight: 500;
}

.contetnt-title-forms {
  font-size: 24px;
  font-weight: 700;
}

/* .btnSend {
  padding: 10px 16px !important;
  border-radius: 4px !important;
} */

.content-input-forms-policity {
  padding-top: 50px;
}

.content-btn-send {
  display: flex;
  justify-content: start;
  margin-top: 20px;
}

#ex3-tab-1,
#ex3-tab-2 {
  text-transform: none;
}

.overlayDiv2 {
  position: absolute;
  height: 100%;
  width: 20%;
  left: 0;
  top: 0;
  background: linear-gradient(
    90deg,
    rgba(0, 85, 209, 0.7) 0%,
    rgba(0, 87, 255, 0) 100%
  );
}

.block-content-privacy-policy {
  padding-top: 30px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
}

.content-items-privacy {
  margin-top: 30px !important;
}
@media screen and (max-width: 580px) {
  .block-content-privacy-policy {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .content-items-privacy {
    margin-top: 15px !important;
  }
  .content-row-teams-carruouser {
    padding: 5px;
  }

  .mainConatinerFlex {
    display: contents !important;
  }

  .containerImgTeams {
    display: -webkit-box !important;
    max-height: 238px !important;
  }

  .footer-copyright {
    font-size: x-small !important;
  }

  .content-input-forms-policity {
    padding-top: 20px;
  }

  .content-description-forms {
    font-size: small;
  }

  .labelForms {
    font-size: 14px !important;
    margin-bottom: 5px;
  }

  .contetnt-title-forms {
    font-size: medium;
  }

  .content-input-forms {
    padding-top: 10px;
  }

  #ex3-tab-1,
  #ex3-tab-2 {
    font-size: x-small !important;
    line-height: 35px !important;
    padding: 0px;
  }

  .btnSend {
    font-size: 14px !important;
    padding: 8px 10px !important;
  }

  .content-forms-clients,
  .content-forms-postulant {
    padding-top: 20px;
  }
}

@media screen and (max-width: 289px) {
  .content-button-header {
    font-size: xx-small !important;
  }

  .image {
    height: 15px !important;
  }

  #ex3-tab-1,
  #ex3-tab-2 {
    font-size: xx-small !important;
  }
}

.content-client-img {
  padding-top: 45px;
  padding-bottom: 45px;
  height: 50px;
}

.container-footer {
  overflow: auto;
}

.clientImg {
  max-width: 280px;
  max-height: 60px;
  opacity: 1;
  transition: opacity 1.5s linear;
  /* transition-delay: s; */
  /* Adding a transition for opacity */
}

.clientImg.hidden {
  opacity: 0;
}

.content-component-list-clients {
  padding: 50px 0px 50px 0px;
}

.component-logo-client {
  text-align: center;
  margin-bottom: 15px;
  /* display: flex;
  justify-content: center; */
}

.container-map {
  height: 448px;
  position: relative;
  width: 672px;

  margin-left: 30px;
}

.border-map {
  width: 224px;
  height: 448px;
  background: rgba(0, 87, 255, 1);
  position: absolute;
  right: 0;
}

.google-maps-content {
  width: 648px;
  height: 400px;
  position: absolute;
  top: 24px;
  left: 0;
}

.container-component-map {
  padding-top: 50px;
}

.content-description-ciircle {
  text-align: center;
}

.content-block-services {
  padding-top: 50px;
}

.content-load-spninner-home {
  padding-top: 50%;
}

.content-load-spninner {
  padding: 20%;
  background: rgba(0, 87, 255, 1);
}

.content-transition {
  opacity: 1;
  transition: opacity 1.5s ease-in-out;
}

.content-transition.loading {
  opacity: 0;
}

@media screen and (max-width: 770px) {
  .content-component-list-clients {
    padding: 20px 0px 20px 0px;
  }

  .content-description-ciircle {
    width: 210px;
  }

  .card-title-client {
    font-size: 18px !important;
  }
  .card-title-privacy {
    font-weight: 700;
    font-size: 16px !important;
  }
  .card-description-client,
  .card-description-privacy {
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 20px !important;
  }

  .container-component-map {
    padding-top: 25px;
    padding-bottom: 20px;
  }

  .title-collapse {
    font-size: 18px !important;
    margin-left: 5px;
  }

  .index-collapse {
    font-size: 16px !important;
  }

  .content-block-services {
    padding-top: 20px;
  }

  .content-block-client-logo {
    margin-top: 45px !important;
  }

  .container-map {
    position: relative;
    width: 98% !important;
  }

  .border-map {
    width: 50%;
    height: 100% !important;
    background: rgba(0, 87, 255, 1);
    position: absolute;
    left: 47%;
  }

  .google-maps-content {
    width: 95% !important;
    height: 95% !important;
    position: absolute;
    top: 11px;
    left: -5px;
  }

  .title-map {
    width: 100% !important;
  }
}

.title-map {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  display: inline-block;
  position: relative;
}

@media screen and (max-width: 1400px) {
  .content-map-description {
    text-align: start !important;
  }

  .title-map,
  .adress-map {
    text-align: start !important;
  }
}

.content-component-form {
  height: 100%;
  background: rgba(242, 246, 255, 1);
}

.boxMap {
  margin-top: 4%;
}

.mapContainer {
  position: relative;
}

.maxLength {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}

/* Estilos para los tabs */
.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border: 1px solid rgba(0, 87, 255, 1);
  color: rgba(0, 87, 255, 1);
}

.tab.selected {
  background-color: rgba(0, 87, 255, 1);
  color: white;
}

/* Estilos para las imágenes */
.images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-wrapperz {
  cursor: pointer;
  overflow: hidden;
}

#component-gallery-projects-max {
  width: 100%;
  margin-top: 7%;
}

.image-wrapper {
  width: 300px;
  height: 450px;
  margin: 10px;
  overflow: hidden;
}

.content-move {
  margin-left: -35%;
  height: 100%;
}

.btn-projects {
  background: rgba(47, 140, 255, 1);
  font-size: 18px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  padding: 10px 16px 10px 16px !important;
  box-shadow: none !important;
}

.portfolio-image {
  width: 100%;
  height: 100%;
  transition: transform 0.4s ease-in-out;
}

.portfolio-image:hover {
  transform: scale(1.2);
}

.mapIframe {
  width: 100%;
  height: 400px;
  border-right: 20px solid rgba(0, 87, 255, 1);
  width: 658px;
  height: 400px;
}

.mapContainer::before,
.mapContainer::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 20px;
  background-color: rgba(0, 87, 255, 1);
}

.mapContainer::before {
  top: 0;
  right: 0;
}

.mapContainer::after {
  bottom: 0;
  right: 0;
}

/* Estilos CSS */
/* Estilos CSS */

.containerImgTeams {
  position: relative;
}

.imgTeams {
  display: block;
}

.imgBorder {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  border-bottom: 25px solid rgba(0, 87, 255, 1);
  /* Cambia el color del borde aquí si lo deseas */
  /* opacity: 0.5; Cambia la opacidad del borde aquí si lo deseas */
}

.overlayDiv {
  position: absolute;
  top: 0;
  left: -30px;
  width: 96px;
  height: 48px;
  background-color: rgba(0, 70, 204, 0.8);
  /* Cambia el color de fondo del div aquí si lo deseas */
}

code {
  font-family: "Montserrat";
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(208, 212, 222, 1),
    rgba(47, 141, 255, 0.558)
  );
  display: flex;
  justify-content: center;
  animation: gradientAnimation 10s linear infinite;
  /* Ajusta la duración de la animación (10s) como desees */
  align-items: center;
  z-index: 9999;
  /* Asegúrate de que el componente esté en la parte superior */
}

.image-container {
  position: relative;
}

.moving-image {
  position: relative;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 378px;
  transition: transform 1.5s ease;
}

.image-container {
  width: 100%;
  height: 380px;
  background: rgba(0, 87, 255, 1);
  display: block;
  transition: transform 3s ease;
}

.image-container:hover img {
  transform: translateX(90%);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none;
  color: #fff;
}

.btnCLigth {
  color: rgba(0, 87, 255, 1);
  font-weight: 500;
}

.content-box {
  text-align: center;
  max-width: 495px;
}

.title-box-overlay {
  font-family: Montserrat;
  font-size: 34px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.02em;
  text-align: center;
}

.contenedor-imagen {
  max-width: 100%;
  /* Ancho máximo para el contenedor */
  height: 268px;
  /* Altura fija */
  overflow: hidden;
  /* Oculta el contenido que excede el contenedor */

  /* Propiedades de flexbox para alinear la imagen en la parte superior */
  display: flex;
  align-items: flex-start;
}

.image-prueba {
  width: 100%;
  /* Ancho de la imagen al 100% del contenedor */
  height: auto;
}

@media (max-width: 660px) {
  .maxLength {
    font-size: 13px;
  }

  .title-box-overlay {
    font-size: 22px;
    /* Ajusta el tamaño de la fuente para pantallas más pequeñas */
  }

  .description-circle {
    font-size: 14px !important;
    width: 100% !important;
    line-height: 20px !important;
  }

  .btn-contact-us {
    padding: 8px 10px !important;
    font-size: 14px !important;
    margin-top: 20px !important;
  }
  .text-decore-btn {
    color: rgba(0, 87, 255, 1);
    text-decoration: none !important;
  }
  .btn-client {
    padding: 5px 8px !important;
    font-size: 14px !important;
  }
}

.content-block-work-delfosti {
  margin-top: 60px;
}

@media (max-width: 768px) {
  .overlayDiv {
    height: 32px !important;
  }

  .imgBorder {
    border-bottom: 15px solid rgba(0, 87, 255, 1) !important;
  }

  .content-block-work-delfosti {
    margin-top: 30px !important;
  }

  .btn-projects {
    background: rgba(47, 140, 255, 1);
    font-size: 14px !important;

    text-transform: none !important;
    padding: 8px 10px !important;
  }
}

.btn-contact-us {
  color: rgba(0, 87, 255, 1) !important;
  font-weight: 500 !important;
  text-transform: none;
  padding: 10px 16px;
  box-shadow: none;
  margin-top: 50px;
}

.description-circle {
  margin: auto;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  width: 40%;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: center;
}

.loading-content {
  text-align: center;
  padding: 20px;

  border-radius: 5px;
}

footer {
  color: white;
  margin-top: 7rem;
  text-align: center;
  margin-bottom: 0%;
}

.titleObject,
.titleObjectMap {
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.02em;
  display: inline-block;
  position: relative;
}

.borderLineTitle,
.borderLineTitleBlue {
  background-color: #0057ff;
  height: 10px;
  width: 40px;
  position: absolute;
  right: 0;
}

.descriptionObject {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(29, 29, 29, 1);
}

/* Add this to your CSS file or style section */
.Paragraph {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.Large {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.Medium {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.accordion-container {
  background-color: none;
}

.footer-footer {
  margin-bottom: 0%;
}

.content-circle {
  display: flex;
  margin-top: 30px;
  justify-content: center;
  margin: auto;
}

/* .content-block-circle {
  margin-top: 50px;
} */

.block-circles-component {
  margin-top: 20px;
}

.footer-copyright {
  text-align: end;
}

.col-circle {
  display: flex !important;
  justify-content: center !important;
}

@media screen and (max-width: 1090px) {
  .image-container img {
    width: auto !important;
  }

  .content-block-circle {
    margin-top: 8px;
  }

  .circle {
    width: 279px !important;
    height: 279px !important;
    margin: auto !important;
  }

  .col-circle {
    margin-top: 4% !important;
    padding: 5px 15px 5px 15px !important;
  }

  .row-center {
    display: block !important;
  }
}

@media screen and (max-width: 450px) {
  .title-map {
    font-size: 20px;
  }

  .card-collapse {
    margin-left: 35px !important;
  }
}

@media screen and (max-width: 550px) {
  .image-container {
    width: 100%;
    height: 280px;
    overflow: hidden;
    background: rgba(0, 87, 255, 1);
    display: block;
    transition: transform 3s ease;
  }

  .image-container img {
    overflow: auto;
    width: auto !important;
  }
}

#component-gallery-projects-min {
  display: none;
}

.content-nav-briefcase {
  padding-top: 60px;
  padding-bottom: 50px;
}

.btn-layouts-services {
  padding-top: 50px;
  display: flex;
  justify-content: center;
}

.content-briefcase-min {
  display: none;
}
.content-briefcase-max {
  display: block;
}
.content-select-tabs {
  width: 100%;
}

.form-select-briefcase {
  color: rgba(0, 87, 255, 1) !important;
  border: 1px solid rgba(0, 87, 255, 1) !important;
}

.content-block-carrousel-teams,
.content-block-component-working-delfos {
  padding-top: 50px;
}

.form-select-briefcase::placeholder {
  color: rgba(0, 87, 255, 1) !important;
  border: 1px solid rgba(0, 87, 255, 1) !important;
}

/* SELECT:not(:-webkit-autofill) {
  /* color: rgba(0, 87, 255, 1) !important;
  border: 1px solid rgba(0, 87, 255, 1) !important; */

.title-map {
  text-align: end;
  width: 70%;
  padding-top: 17px;
}

.component-block-choose-max {
  padding-top: 50px;
  justify-content: center;
}

.component-block-choose-min {
  padding-top: 50px;
}

.adress-map {
  text-align: end;
  margin: auto;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  color: rgba(124, 126, 134, 1);
}

.content-map-description {
  text-align: end;
}

.btn-client {
  padding: 10px 16px;
  box-shadow: none;
  background: rgba(47, 140, 255, 1);
}

@media screen and (max-width: 1400px) {
  .adress-map {
    line-height: 20px;
    font-size: 14px;
    margin-top: 0px;
  }

  .borderLineTitle {
    display: none;
  }

  .title-map {
    padding-top: 5px !important;
  }
}

.content-title-client {
  text-align: center;
}

.content-btn-client {
  display: flex;
  justify-content: center;
  margin: auto;
  padding-top: 50px;
}

.content-block-client-logo {
  margin-top: 60px;
}

@media screen and (max-width: 770px) {
  .content-title-client {
    text-align: start;
  }

  .btn-client {
    padding: 8px 12px !important;
    box-shadow: none;
  }

  .title-primary,
  .subtitle-primary {
    font-size: x-large !important;
  }

  .tabs-briefcase {
    display: none;
  }

  .content-circle {
    display: block;
  }

  .briefcase-content-max {
    display: none !important;
  }

  .content-briefcase-min {
    display: block !important;
  }
  .content-briefcase-max {
    display: none;
  }

  .btn-layouts-services {
    padding-top: 20px !important;
    display: flex;
    justify-content: center;
  }

  .content-block-objetives {
    margin-top: 20px;
  }

  #component-gallery-projects-max {
    display: none;
  }

  #component-gallery-projects-min {
    display: block !important;
    margin-top: 50px !important;
  }

  .image-wrapperz {
    margin-bottom: 12px;
    margin-top: 10px;
  }

  .content-btn-client {
    display: flex;
    justify-content: center;
    margin: auto;
    padding-top: 20px;
  }

  .btn-client {
    font-size: 18px;
    font-weight: 500;
    box-shadow: none;
    text-transform: none !important;
    padding: 10px 15px 10px 15px;
  }

  .clientImg {
    max-width: 100% !important;
    height: 60px !important;
    padding: 10px;
  }

  .titleObject,
  .titleObjectMap {
    text-align: start !important;
  }

  .content-client-img {
    padding-top: 15px;
    height: 50px;
  }

  .borderLineTitle {
    display: none;
  }

  .footer-copyright {
    text-align: start;
  }

  .row-center {
    display: block !important;
  }

  .tle-circle {
    font-size: 28px !important;
  }

  .ds-circle {
    width: auto !important;
    font-size: medium !important;
  }
}

.title-linkd {
  font-size: 34px;
  color: rgba(29, 29, 29, 1);
  font-weight: 700;
}

.img-link {
  height: 30px;
}

.subtitle-lkd {
  font-size: 18px;
  color: rgba(29, 29, 29, 1);
  font-weight: 500;
}

.content-block-component-linkld {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
}

.content-lkd-description {
  padding-top: 7%;
}

@media screen and (max-width: 1227px) {
  .img-link {
    height: 28px;
  }

  .title-linkd {
    font-size: x-large;
    color: rgba(29, 29, 29, 1);
    font-weight: 700;
  }

  .subtitle-lkd {
    font-size: medium;
  }

  .row-content-componet-lkd {
    display: flex;
    justify-content: center;
  }

  .conten-img-likdn {
    display: none;
  }

  .content-lkd-description {
    padding-top: 0px;
  }

  .content-block-component-linkld {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
  }

  .content-lkd {
    display: flex;
    justify-content: center;
    margin: auto;
    background: rgba(242, 246, 255, 1);
    width: 100%;
    padding: 0;
    /* margin: 5%; */
    border-radius: 16px;
  }
}

.containerSection {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.tle-circle {
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
}

.ds-circle {
  width: 240px;
  display: flex;
  justify-content: center;
  font-family: Montserrat;
  font-size: 20px;
  text-shadow: 0 0 rgb(80, 81, 83);
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  color: rgba(124, 126, 134, 1);
}

.circle {
  width: 336px;
  height: 336px;
  border-radius: 50%;
  background-color: #e1e1e6b3;
  color: #0057ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.6s ease-out;
}

.circle.active {
  transition: background-color 1s ease-out;
  background-color: #0057ff;
  color: white;
}

.circle.active .ds-circle {
  color: white;
}

.circle::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: rgba(255, 0, 0, 0);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  /* Inicialmente oculto */
  pointer-events: none;
  /* Evita que el pseudo-elemento bloquee el evento del círculo */
}

.circle:hover::after {
  opacity: 1;
  /* Mostrar el punto rojo al pasar el cursor sobre el círculo */
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 3rem;
}

.footer__socials {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 4rem;
}

.footer__socials a {
  background: var(--color-bg);
  color: var(--color-white);
  padding: 0.8rem;
  border-radius: 0.7rem;
  display: flex;
  border: 1px solid transparent;
}

.footer__socials a:hover {
  background: transparent;
  color: var(--color-bg);
  border-color: var(--color-bg);
}

.footer__copyright {
  margin-bottom: 4rem;
  color: var(--color-bg);
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

@media screen and (max-width: 600px) {
  .permalinks {
    flex-direction: column;
    gap: 1.5rem;
  }

  .footer__socials {
    margin-bottom: 2.6rem;
  }
}

* {
  font-family: "Montserrat";
}

.noneDecoder {
  text-decoration: none;
  color: white;
}

.hover-animation-blue {
  display: inline-block;
  position: relative;
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 87, 255, 1);
}

.hover-animation-blue :hover {
  display: inline-block;
  position: relative;
  font-weight: 700;
  color: rgba(0, 87, 255, 1);
}

.blue-background {
  top: 0;
  left: 0;
  width: 100%;
  height: 410px;
  background-color: rgba(0, 87, 255, 1);
}

.image-container-circle {
  top: 0;
  left: -50;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.hover-image {
  width: 100%;
  height: 450px;
  transition: transform 1s ease-in-out;
}

.hover-image:hover {
  transform: translateX(70%);
}

.hover-animation-blue:after {
  content: "";
  position: absolute;
  margin-left: 1px;
  width: 20%;
  transform: scaleX(0);
  height: 4px;
  left: 0;
  top: 26px;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.2s ease-out;
}

.hover-animation-blue:hover:after,
.hover-animation-white:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.hover-animation-white {
  display: inline-block;
  position: relative;
  font-size: 20px;
  font-weight: 500;
  color: #0087ca;
}

.hover-animation-white:hover {
  display: inline-block;
  position: relative;
  font-weight: 500;
  color: #ffffff;
}

.hover-animation-white:after {
  content: "";
  position: absolute;
  margin-left: 1px;
  width: 20%;
  transform: scaleX(0);
  height: 4px;
  top: 25px;
  left: 0;
  background-color: #ffffff;
  transform-origin: bottom right;
  transition: transform 0.2s ease-out;
}

.hover-underline-animation {
  margin-right: 40px;
}

.content-logo-header {
  margin-left: 25px;
}

.navbar-collapse {
  margin-left: 45px;
}

.navbar-collapse-true-hidden {
  margin-right: 20px;
}

.containerNavbarHome {
  height: 950px;
  width: 100%;
  background: none;
  /* background-image: url('./assets/img/components/background/image_22.svg'); */
}

#video {
  width: 100%;
  opacity: 88%;
}

.titleBlue {
  color: #0057ff;
  font-family: Montserrat;
  font-size: 64px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -0.02em;
  text-align: left;
  top: 20px;
  width: 500px;
  margin-top: 100px;
  margin-left: 50px;
}

.bg-body-tertiary {
  background: none !important;
}

.bg-body-white {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.tabs-briefcase {
  padding-bottom: 20px;
}

.bg-body-blue {
  background-color: none;
}

/* styles.css (o el nombre que hayas usado) */

/* Contenedor para cada imagen, proporciona un espacio para el efecto de zoom */
.image-containerpr {
  overflow: hidden;
}

/* Regla para aplicar el zoom al pasar el ratón por encima */
.zoom-image {
  transition: transform 0.3s ease;
  margin: 2px;
  padding: 2px;
  height: 100%;
}

.zoom-image:hover {
  transform: scale(1.1);
  /* Puedes ajustar el valor aquí para aumentar o reducir el zoom */
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: rgba(0, 87, 255, 1) !important;
  background-color: #f2f6ff !important;
  border: none !important;
  border-bottom: 5px solid rgba(0, 87, 255, 1) !important;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
  color: rgba(86, 175, 255, 1);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  border-bottom: 5px solid rgba(86, 175, 255, 1);
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.img-adj {
  margin: 2px;
  margin-right: 10px;
}

.content-navbar-responsive-mobile {
  margin-top: 45px;
}

.document-load-file {
  color: rgba(47, 140, 255, 1);
  cursor: pointer;
}

.btn-cv {
  text-transform: none;
  background: none !important;
  color: rgba(47, 140, 255, 1) !important;
  padding: 12px 16px 12px 16px !important;
  box-shadow: none !important;
  border-radius: 5px;
  border: 1px solid;
}

.btn-cv.uploaded {
  text-transform: none;
  color: white !important;
  background-color: rgba(47, 140, 255, 1) !important;
  padding: 12px 16px 12px 16px !important;
  box-shadow: none !important;
  border-radius: 5px;
  border: 1px solid;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  /* background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e); */
  color: rgb(106, 95, 101) !important;
  margin-top: 25%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  /* background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e); */
  background: none !important;
}

/* button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  margin: -70px !important;
} */

.btnSend {
  background-color: rgba(47, 140, 255, 1) !important;
  color: white !important;
  margin-top: 15px !important;
  text-transform: none !important;
}

/* <-------------------Block Chosee---------------------> */
.image-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

/* .image-column {

}

.image-column img {
 
}

.info-column {
  
} */

.data-item {
  margin-bottom: 10px;
  cursor: pointer;
  color: rgba(185, 188, 193, 1);
  margin-left: 20px;
  border-left: 8px solid #0055ff00;
}

/* Estilos para la paginación (círculos) */

.content-bloc-choose-margin {
  width: 100%;
  height: 100%;
  position: relative;
  border: none !important;
}

.borde-left-responsive {
  background: rgba(0, 87, 255, 1);
  height: 100%;
  width: 4%;
  position: absolute;
  top: 0;
  left: 0px;
}

.borde-rigth-responsive {
  background: rgba(0, 87, 255, 1);
  height: 15%;
  width: 4%;
  position: absolute;
  top: 0;
  right: 0;
}

.borde-top-rigth-responsive {
  background: rgba(0, 87, 255, 1);
  height: 4%;
  width: 15%;
  position: absolute;
  top: 0;
  right: 0;
}

.borde-top-left-responsive {
  background: rgba(0, 87, 255, 1);
  height: 4%;
  width: 15%;
  position: absolute;
  top: 0;
  left: 5px;
}

.borde-bottom-left-responsive {
  background: rgba(0, 87, 255, 1);
  height: 4%;
  width: 15%;
  position: absolute;
  bottom: 0;

  top: 99.9%;
}

.conetnt-component-block-choose-min {
  display: none;
}

@media screen and (max-width: 900px) {
  .content-block-component-working-delfos {
    padding-top: 30px;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    border-bottom: 2px solid rgba(0, 87, 255, 1) !important;
  }

  .nav-link {
    border-bottom: 2px solid rgba(86, 175, 255, 1) !important;
  }

  /* 
  .carousel-frmk {
    height: 25px !important;

  } */

  .conetnt-component-block-choose-max {
    display: none;
  }

  .conetnt-component-block-choose-min {
    display: block;
  }

  .component-block-choose-min {
    padding-top: 20px !important;
  }
}

.input-error {
  border: 1px solid #ef4444;
}

@media screen and (max-width: 992px) {
  .content-block-carrousel-teams {
    padding-top: 20px;
  }

  .block-choose-img-carrousel {
    margin-left: 1% !important;
    margin-top: 3.9% !important;
  }
}

.content-gallery-block {
  height: 560px;
  position: relative;
  width: 560px;
  text-align: center;
  margin: auto;
}

@media screen and (max-width: 1406px) {
  .block-choose-img-carrousel {
    width: 95%;
    height: 90%;
    margin-left: 9px;
    margin-top: 40px;
  }

  .content-carrousel-min {
    margin-top: 5%;
  }

  .content-data-item {
    margin-left: 0px !important;
    margin-top: 20px;
  }
}

.title-blockChoose {
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

.title-blockChoose-active {
  color: #0057ff;
}

.content-data-item {
  margin-left: 20px;
}

.description-blockChose {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: start !important;
}

.data-item:hover {
  color: rgba(29, 29, 29, 1);
}

.selected {
  color: rgba(29, 29, 29, 1);
  border-left: 8px solid #0057ff;
}

.content-block-gallery-briefcase {
  margin: 0px;
}

.content-block-objetives {
  background: rgba(242, 246, 255, 1);
  margin-top: 50px;
}

.component-objectives {
  padding: 50px 20px;
}

.border-top-left {
  width: 280px;
  background: #0057ff;
  height: 100%;
}

.border-top-rigth {
  background: #0057ff;
  width: 80px;
  height: 80px;
  top: 0;
  position: absolute;
  right: 0;
}

.img-choose {
  position: absolute;
  top: 24px;
  left: 25px;
}

/* Add this to your CSS file or style section */
.collapsing-element {
  transition: height 0.3s ease-out;
  /* Adjust the duration and easing to your preference */
  overflow: hidden;
}

.btn-allServices {
  background-color: rgba(47, 140, 255, 1);
  color: white;
  padding: 10px 16px;
  border-radius: 4px;
  margin-top: 40px;
  height: 48px;
}

.btn-allServices:hover {
  background-color: #0057ff;
  color: white;
}

.containerLkd {
  background-color: white;
  position: relative;
  width: 440px;
  height: 160px;
  /* top: 32px
left: 40px */
}

.border-blue-top-large {
  width: 220px;
  background: rgba(0, 87, 255, 1);
  height: 15px;
  position: absolute;
  top: 0;
  right: 0;
}

.border-blue-bottom-large {
  width: 96px;
  background: rgba(0, 70, 204, 0.8);
  height: 50px;
  position: absolute;
  bottom: 0px;
}

.opacityLkd {
  width: 204px;
  height: 144px;
  /* top: 48px; */
  position: absolute;
  /* left: 56px; */
  top: 15px;
  left: 16px;
  background: linear-gradient(
    90deg,
    rgba(0, 85, 209, 0.7) 0%,
    rgba(0, 87, 255, 0) 100%
  );
}

.lkdImg {
  left: 25px;
  margin-left: 16px;
  margin-top: 15px;
}

.content-header-3d {
  display: block;
  margin-top: -199px;
  height: 600px;
}

.title-header {
  position: absolute;
  /* left: 74px;
  top: 350px;
  width: 474px;
  height: 301px; */
  top: 300px;
  z-index: 1;
  left: 45px;
  width: auto;
  max-width: 474px;
  font-family: Montserrat;
  font-size: 64px;
  font-weight: 800;
  line-height: 72px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .btn-allServices {
    font-size: 14px !important;
    padding: 10px 16px !important;
    border-radius: 4px;
    margin-top: 40px;
    height: 48px !important;
  }

  .bg-body-white,
  .navbar,
  .navbar-expand-lg {
    padding: 20px 0px 0px 0px !important;
  }

  /* .title-header {
    left: 20px;
    width: 90%;
    font-size: 32px;
    line-height: 40px;
  } */
}

.content-block-header {
  position: relative;
  background: none;
  height: 800px;

  overflow: auto;
}

.MapGoogle {
  height: 400px;
  width: 628px;
  margin-top: 28px;
}

.containerMapa {
  height: 448px;
  position: relative;
}

.border-top-blue {
  background: rgba(0, 87, 255, 1);
  height: 28px;
  width: 224px;
  right: 0;
  position: absolute;
}

.border-rigth-blue {
  background: rgba(0, 87, 255, 1);
  width: 28px;
  height: 100%;
  right: 0;
  position: absolute;
}

.border-bottom-blue {
  background: rgba(0, 87, 255, 1);
  height: 28px;
  width: 224px;
  right: 0;
  position: absolute;
  bottom: 0px;
}

/* //-------------------COLLAPSE CSS---------- */
.title-collapse {
  font-size: 22px;
  font-weight: 700;
  cursor: pointer;
}

.title-collapse:hover,
.title-collapse-active {
  color: rgba(0, 87, 255, 1);
}

.description-collapse {
  font-size: 18px;
  font-weight: 500;
  color: rgba(85, 86, 90, 1);
}

.index-collapse {
  color: rgba(0, 87, 255, 1);
  font-size: 22px;
  font-weight: 700;
}

.content-collapse-object {
  margin-top: 12%;
  margin-left: 8%;
}

.content-title-collapse {
  margin: 4%;
}

.card-collapse {
  margin-left: 51px;
  margin-bottom: 35px;
}

@media screen and (max-width: 351px) {
  .image-container img {
    width: auto !important;
    opacity: 1 !important;
  }

  .card-collapse {
    margin-left: 10% !important;
  }

  .title-linkd {
    font-size: large;
  }

  .subtitle-lkd {
    font-size: small;
  }
}

@media screen and (max-width: 290px) {
  .title-linkd {
    font-size: medium;
  }

  .subtitle-lkd {
    font-size: x-small;
  }

  .img-link {
    width: 20px;
  }
}

.carousel-control-next-icon {
  position: absolute !important;
  right: 0 !important;
}

.carousel-control-prev-icon {
  position: absolute !important;
  left: 0 !important;
}

@media screen and (max-width: 1235px) {
  .content-arrow-carrousel-responsive {
    display: flex;
    justify-content: end;
    padding-top: 30px;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: none !important;
  }

  /* .carousel-control-prev-icon:after {
   background: url(./assets/img/arrowLeft.svg) !important;
    content: '' !important;
  } */
}

.img-email {
  margin: 0px;
  padding: 0px 5px 0px 0px;
}

.policity-span-change {
  color: rgba(47, 140, 255, 1);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

@media screen and (max-width: 574px) {
  .policity-span {
    font-size: 14px;
  }

  .policity-span-change {
    font-size: 12px;
  }

  .title-services {
    font-size: medium !important;
  }

  .content-nav-briefcase {
    padding-bottom: 0px;
  }

  .content-arrow-carrousel-responsive {
    padding-top: 15px;
  }

  .content-block-component-working-delfos {
    padding-top: 15px;
  }

  .descriptionObject,
  .descriptionTitle {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .title-services {
    font-size: x-large;
  }

  .title-footer {
    font-size: x-large !important;
  }

  .img-email {
    width: 15px !important;
  }

  .subtitle-email-footer {
    font-size: large !important;
  }
}

.content-components-header {
  padding-top: 20px;
}
.content-components-header-home {
}
@media screen and (max-width: 997px) {
  .content-components-header {
    padding-top: 8px !important;
  }
  .content-components-header-home {
  }
  .btn-layouts-services {
    padding-top: 15px !important;
  }

  .content-block-objetives {
    margin-top: 30px !important;
  }

  .title-footer {
    font-size: x-large;
  }

  .content-car-list-client {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .content-footer {
    padding: 20px 5px !important;
  }

  .content-collapse-object {
    margin-top: 20px;
    margin-left: 0px;
  }

  .content-title-collapse {
    margin-top: 9%;
  }
}

.nav-bar-blue-animation {
  color: rgba(0, 85, 255, 0.628) !important;
}

.nav-bar-blue-animation:hover {
  color: rgba(0, 87, 255, 1) !important;
}

.nav-bar-white-animation {
  color: rgba(255, 255, 255, 0.628) !important;
}

.nav-bar-white-animation:hover {
  color: rgb(255, 255, 255) !important;
}

/* .footer-footer {
  background-color: var(--color-footer);

} */
footer {
  background-color: var(--color-footer);
  width: 100%;
  height: auto;
  margin-top: 0px;
}

.content-footer {
  padding: 30px 30px 30px 30px;
}

.subtitle-email-footer {
  font-size: 19px;
  font-weight: 700;
}

.title-footer {
  font-size: 40px;
  font-weight: 700;
}

.content-bottom-footer {
  padding: 19px 0px;
}

.subtitle-footer {
  font-size: 16px;
  padding: 5px;
  padding-left: 0px;
}

.subtitle-email-footer {
  font-size: 24px;
}

.navbar {
  box-shadow: none !important;
}

.content-title-primary {
  text-align: center;
  overflow: hidden;
  padding-bottom: 10px;
  padding-top: 25px;
}

.title-primary {
  color: rgba(29, 29, 29, 1);
  font-weight: 700;
  font-size: 36px;
}

.subtitle-primary {
  color: rgba(0, 87, 255, 1);
  font-weight: 700;
  font-size: 36px;
}

.content-button-header {
  text-transform: none !important;
  padding: 10px 16px !important;
  font-weight: 500px !important;
}

.button-hover-white {
  color: rgba(0, 87, 255, 1);
  font-weight: 500;
}

.button-hover-none-white,
.button-hover-none-blue,
.button-hover-none-blue {
  font-weight: 500;
  text-decoration: none !important;
}
.button-hover-none-blue-hover {
  color: #0057ff;
}
.content-component-forms {
  padding: 5%;
}

.content-img-team-seconds {
  display: flex;
  justify-content: end;
  padding-top: 20px;
}

.first-img-teams {
  width: 100%;
}

.col-teams-rigth {
  width: 100%;
}

.container-img-teams {
  background: rgba(242, 246, 255, 1);
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 64px;
}

.error-field {
  border-color: #ef4444 !important;
}

.error-message {
  color: #ef4444;
  font-size: 14px;
}

.delete-file-icon {
  margin-left: 15px;
  cursor: pointer;
  color: #ef4444;
}

.component-carousel-team {
  padding: 2%;
  width: 100%;
}

.content-row-teams-carruouser {
  display: flex !important;
  justify-content: center !important;
}

.content-data-testimony-responsive {
  background-color: #55565a;
  max-height: 480px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  width: 98%;
  height: 100%;
}

/* //conocenos */
@media screen and (max-width: 1235px) {
  .content-data-testimony {
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 16px;
    width: 98% !important;
    height: 100%;
  }

  .containerImgTeams {
    height: auto;
    display: flow;
    align-content: stretch;
    justify-items: center;
    justify-content: center;
    align-items: center;
    max-height: 500px;
    width: 100%;
  }

  .imgTeams {
    width: 100% !important;
    object-position: center top;
    object-fit: cover;
    height: 100%;
  }
}

#carouselTeams {
  padding-top: 50px;
}

.mainContainerContent {
  display: contents;
}

.card-data-testimony {
  margin: 20px 40px 20px 20px;
}

.mainConatinerFlex {
  display: flex;
  overflow: hidden;
}

.card-testimony-footer {
  border: none !important;
  background: none !important;
  margin: 5% 0px 0px 18px;
}

@media screen and (max-width: 1235px) {
}

.icon-testimony {
  margin: 13px 0px 0px 8px;
}

.data-testimony {
  color: rgba(255, 255, 255, 1);
  margin-top: -22px;
  margin-left: 26px;
}

.footer-text-linkd {
  margin-bottom: 35px;
}

.card-footer-name {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: rgba(255, 255, 255, 1);
}

.card-footer-job {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(185, 188, 193, 1);
}

.show-navbar {
  display: none !important;
}

.img-responsive-email {
  height: 25px;
}

.text-content-navbar {
  color: white !important;
  font-family: Montserrat;
  font-size: large !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  letter-spacing: -0.02em !important;
  text-align: left !important;
  padding: 18px 0px !important;
  width: 95% !important;
  border-bottom: 1px solid rgba(124, 126, 134, 1) !important;
}

.card-footer-navbar.scrollable {
  margin-top: 40px;
  position: relative !important;
  max-height: calc(100vh - 300px);
  /* Limitar la altura máxima */
  overflow-y: auto;
  /* Habilitar el scroll vertical si el contenido es mayor */
}

@media screen and(max-width:414px) {
  .title-cicrcle {
    font-size: x-small !important;
  }

  .text-content-navbar,
  .text-email-footer {
    font-size: xx-small !important;
    width: 100% !important;
  }

  .title-objetives {
    font-size: x-large !important;
  }

  .card-nav {
    padding: 2% 2% !important;
  }

  .text-content-navbar {
    font-size: medium !important;
  }
}

.content-colum-resosive-navbar {
  margin-top: 15px !important;
}

.card-nav,
.card-body-navbar,
.card-header-navbar,
.card-footer-navbar {
  background: none !important;
  border: 0px !important;
  box-shadow: none !important;
}

.content-body-navbar {
  margin-top: 50px;
}

.close-menu {
  height: 14px;
}

.logoDelfos {
  height: 18px;
}

.card-nav {
  padding: 5% 5% !important;
}

.card-footer-navbar {
  position: absolute;
  bottom: 15px;
  color: white !important;
  /* padding-top: 23% !important; */
  padding-left: 0px !important;
}

.lkd-text {
  margin-left: 10px;
  color: white;
}

.content-nav-fluid {
  margin-top: 8% !important;
}

.text-navbar-footer {
  margin-top: 15%;
  font-size: small;
  text-align: start;
}

.text-email-footer {
  font-size: large;
  margin-left: 5px;
}

.col-left-objetives,
.titleObject,
.descriptionObject,
.titleObjectMap {
  padding-bottom: 20px;
}

@media screen and (max-width: 1200px) {
  .carousel-control-next,
  .carousel-control-prev {
    display: none !important;
  }

  .second-img-teams {
    width: 100%;
  }

  .thirth-img-teams {
    margin-top: 20px;
    width: 100%;
  }
}

.thirth-img-teams {
  width: 100%;
}

.card-data-testimony {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  height: 90% !important;
}

.content-data-testimony {
  background-color: #55565a;
  max-height: 480px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.content-button-header {
  border-radius: 4px !important;
}

.card-client-list.card {
  padding: 20px 0;
}

@media screen and (max-width: 991px) {
  .lkd-text {
    margin-left: 5px !important;
  }
  .cursorCustom,
  .cursorDefoult {
    display: none !important;
  }
  * {
    cursor: initial !important;
  }
  .card-testimony-footer {
    border: none !important;
    background: none !important;
    margin: 5% 0px 0px 0px;
  }

  #carouselTeams {
    padding: 20px 4px 0px 4px !important;
  }

  .icon-testimony {
    height: 30px;
    margin: 10px 0px 0px 0px;
  }

  .card-footer-job,
  .data-testimony {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .data-testimony {
    margin: -15px 15px 15px 14px !important;
  }

  .card-footer-name {
    font-size: 18px !important;
    line-height: 20px !important;
  }

  .card-data-testimony {
    margin: 0px !important;
    height: 90% !important;
    background: none !important;
    box-shadow: none !important;
    border: none !important;
  }

  .title-header {
    display: none;
  }

  .content-block-header {
    height: 630px;
  }

  .content-header-3d {
    height: 700px !important;
  }

  .content-navbar-end {
    display: flex !important;
    justify-content: end !important;
    margin-right: 0px !important;
    padding-right: 0px !important;
  }

  .navbar-toggler {
    display: flex !important;
    justify-content: end !important;
    margin-right: 0px !important;
    padding-right: 0px !important;
  }

  .content-block-circle {
    margin-top: 0px !important;
  }

  .content-block-choose {
    padding-top: 25px !important;
  }

  .borderLineTitleBlue {
    height: 7px !important;
    width: 32px !important;
  }

  .content-button-header {
    font-size: small !important;
    padding: 2px 5px !important;
  }

  .component-objectives {
    padding: 25px 5px !important;
  }

  .logoWhite-delfosti,
  .footer-copyright {
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
    margin: auto;
  }

  .logoWhite-delfosti {
    height: 15px !important;
    margin-bottom: 5px;
  }

  .show-navbar.show {
    padding-left: 15px;
    padding-right: 5px;
    display: block !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 0;
    background-image: url("./assets/img/components/background/background-responsive.png");
    z-index: 100;
    animation: slideDown 0.3s ease-in-out forwards;
  }
}

.logoWhite-delfosti {
  height: 25px;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

.show-navbar.hide {
  animation: slideUp 0.5s ease-in-out forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.section-container {
  position: relative;
  width: 100%;
  height: 400%;
  animation: slideUp 3s linear infinite;
}

.section {
  position: absolute;
  width: 100%;
  height: 25%;
  opacity: 0.5;
}

.section:nth-child(1) {
  background-color: #56afff;
}

.section:nth-child(2) {
  background-color: #2f8cff;
}

.section:nth-child(3) {
  background-color: #0057ff;
}

.section:nth-child(4) {
  background-color: #0057ff;
}

.center-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 24px;
  color: white;
}

@keyframes slideUp {
  0% {
    top: 100%;
  }

  25% {
    top: 0;
  }

  75% {
    top: 0;
  }

  100% {
    top: -300%;
  }
}

.content-block-framework-logo {
  padding-top: 50px;
  padding-bottom: 50px;
}

.content-block-framework {
  padding-top: 50px;
}

@media screen and (max-width: 800px) {
  .description-blockChose,
  .description-collapse,
  .content-href,
  .subtitle-footer,
  .footer-text-linkd {
    font-size: small !important;
  }

  .title-blockChoose {
    font-size: 20px !important;
  }

  .description-blockChose {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .form-control,
  select {
    height: 40px !important;
  }

  .image-overlay {
    padding: 15px !important;
  }

  .content-tecnologies {
    display: none;
  }

  .content-block-framework-logo {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.content-img-tecnologies {
  margin: 20px;
  padding-bottom: 50px;
}

/* 
<---------MODAL----> */
/* styles.css */
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 39, 92, 0.92);
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-modal-content {
  background-color: none;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  box-shadow: none;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: auto;
  position: relative;
}

.custom-modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.content-left-navigation,
.content-rigth-navigation {
  display: flex;
  justify-content: flex-start;
  /* Alinea el contenido a la izquierda */
  align-items: center;
  /* Centra verticalmente */
  height: 100vh;
}

.content-left-navigation-web,
.content-rigth-navigation-web {
  display: flex;
  justify-content: flex-start;
  /* Alinea el contenido a la izquierda */
  align-items: center;
  /* Centra verticalmente */
  height: 100%;
  margin: auto;
}

.modal-navigation-left {
  position: absolute;
  left: 20px;
  cursor: pointer;
}

.modal-navigation-rigth {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

@media screen and (max-width: 580px) {
  .expanded-portfolio-image {
    margin: 60px;
    padding: 50px;
  }

  .custom-modal-content {
    background-color: none;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: center;
    margin: auto;
    position: relative;
  }

  .custom-modal-close {
    position: absolute;
    top: 0;
    right: 10px;
    cursor: pointer;
  }

  .modal-navigation-left {
    position: absolute;
    left: 20px;
    top: initial;
    bottom: 10px !important;
    cursor: pointer;
  }

  .modal-navigation-rigth {
    position: absolute;
    right: 20px;
    top: initial;
    bottom: 10px !important;
    cursor: pointer;
  }
}

/* <----------- animacion escaleras ----> */
@keyframes shadow-pop-tr {
  0% {
    box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e,
      0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
    transform: translateX(0) translateY(0);
  }
  100% {
    box-shadow: 1px -1px #3e3e3e, 2px -2px #3e3e3e, 3px -3px #3e3e3e,
      4px -4px #3e3e3e, 5px -5px #3e3e3e, 6px -6px #3e3e3e, 7px -7px #3e3e3e,
      8px -8px #3e3e3e;
    transform: translateX(-8px) translateY(8px);
  }
}

.shadow-pop-tr {
  animation: shadow-pop-tr 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}
